* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.no-scroll {
  overflow: hidden;
  height: 100vh;
}

body {
  font-family: sans-serif;
  background-color: rgb(251, 248, 245);
}

/* Link */
a {
  text-decoration: unset;
  color: unset;
  display: inline-flex;
}

/* Remove the spinners */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

main {
  /* 100vw - header height - footer height */
  min-height: calc(100vh - 60px - 80px);
}

/* @media only screen and (max-width: 650px) {
  main {
    padding-top: 60px;
  }
} */
